import React from 'react'
import clsx from 'clsx'
import {
  createStyles,
  Dialog,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CMSText from './CMSText'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: 0,
      '& .MuiPaper-root': {
        borderRadius: 0,
        [theme.breakpoints.up('md')]: {
          minWidth: 700,
        },
      },
    },
    modalHead: {
      padding: theme.spacing(2, 3),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: theme.extraColors.greyLight,
      borderBottom: `1px solid ${theme.extraColors.grey}`,
    },
    modalTitle: {
      display: 'inline-flex',
      alignItems: 'center',
      fontWeight: 700,
      lineHeight: 1,
      '&>.MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
      },
    },
    modalTitleWarning: {
      color: theme.palette.error.main,
    },
    modalClose: {
      cursor: 'pointer',
      padding: theme.spacing(0.5),
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: theme.spacing(2),
      textAlign: 'center',
      color: theme.extraColors.greyLight,
      background: theme.extraColors.grey,
      '&:hover, &:focus': {
        background: theme.palette.common.black,
      },
    },
    modalContent: {
      padding: theme.spacing(3),
    },
  })
)

type GlobalModalProps = {
  open: boolean
  onClose: () => void
  children: React.ReactNode
  customClass?: string
  icon?: any
  modalTitle: { data: any; defaultText: any }
  isWarning?: boolean
}

const GlobalModal = ({
  open,
  onClose,
  children,
  customClass,
  icon,
  modalTitle,
  isWarning = false,
}: GlobalModalProps) => {
  const classes = useStyles()

  return (
    <Dialog
      className={clsx(customClass, classes.modal)}
      open={open}
      onClose={onClose}
      maxWidth={'md'}
      disableBackdropClick
    >
      <div className={clsx(`${customClass}__head`, classes.modalHead)}>
        <Typography
          variant="body1"
          component={'h2'}
          className={clsx(
            `${customClass}__title`,
            classes.modalTitle,
            isWarning && `${customClass}__title--warning`,
            isWarning && classes.modalTitleWarning
          )}
        >
          {icon && icon}
          <span>
            <CMSText
              asText
              data={modalTitle.data}
              defaultText={modalTitle.defaultText}
            />
          </span>
        </Typography>
        <CloseIcon
          className={clsx(`${customClass}__close`, classes.modalClose)}
          onClick={onClose}
        />
      </div>
      <div className={clsx(`${customClass}__content`, classes.modalContent)}>
        {children}
      </div>
    </Dialog>
  )
}

export default GlobalModal
