import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import {
  Collapse,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Button from '../Button/Button'
import CMSText from '../Global/CMSText'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[1],
    },
    button: {
      margin: theme.spacing(0, 0.5),
      display: 'inline-block',
      color: theme.palette.common.white,
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0.25),
      },
    },
    questionWrapper: {
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3),
      border: '1px solid transparent',
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.common.black,
      textTransform: 'none',
      '& .MuiButton-label>* ': {
        margin: 0,
      },
    },
    questionWrapperExp: {
      boxShadow: 'none',
      marginBottom: 0,
      borderBottom: 0,
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
      },
    },
    answer: {
      textAlign: 'left',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3),
      '& .MuiCollapse-wrapperInner>*': {
        margin: theme.spacing(0, 0, 1),

        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    dropButton: {
      padding: 5,
      height: 35,
      width: 35,
      borderRadius: '50%',
      border: `1px solid ${theme.extraColors.greyLight}`,
      transition: 'transform .25s ease-in-out',
    },
    dropButtonDown: {
      color: theme.palette.primary.main,
    },
    dropButtonUp: {
      color: theme.palette.common.white,
      transform: 'rotate(180deg)',
    },
  })
)

type Tprops = {
  question?: any
  answer?: any
  questionId?: number
  cat?: number
  handleExpandClick: any
  isExpanded: boolean
  close_others: boolean
}

const FaqQuestion = ({
  question,
  answer,
  questionId,
  handleExpandClick,
  isExpanded,
  cat,
  close_others,
}: Tprops) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [simpleExpand, setSimpleExpand] = useState(false)

  const handleExpandClickSimple = () => {
    setSimpleExpand(!simpleExpand)
  }

  return (
    <div className={clsx('faq', classes.root)}>
      <Typography variant="body1" component="h3">
        <Button
          onClick={
            close_others
              ? () => handleExpandClick(questionId, cat)
              : handleExpandClickSimple
          }
          aria-expanded={close_others ? isExpanded : simpleExpand}
          className={clsx(
            'faq__question',
            classes.questionWrapper,
            !(close_others ? isExpanded : simpleExpand)
              ? classes.questionWrapper
              : classes.questionWrapperExp
          )}
          color="primary"
          size="small"
          disableRipple
        >
          <CMSText
            data={question}
            defaultText={t('texts:general:add_a_text')}
          />
          <KeyboardArrowDown
            className={clsx(
              'faq__btn',
              classes.dropButton,
              !(close_others ? isExpanded : simpleExpand)
                ? classes.dropButtonDown
                : classes.dropButtonUp
            )}
          />
        </Button>
      </Typography>
      <Collapse
        in={close_others ? isExpanded : simpleExpand}
        timeout={0}
        mountOnEnter
        unmountOnExit
        className={clsx('faq__answer', classes.answer)}
      >
        <CMSText data={answer} defaultText={t('texts:general:add_a_text')} />
      </Collapse>
    </div>
  )
}

export default FaqQuestion
