import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import * as prismic from '@prismicio/client'
import {
  Container,
  Typography,
  Box,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  useMediaQuery,
  useTheme,
  Modal,
  Fab,
  Fade,
} from '@material-ui/core'
import {
  columnSpacing,
  setFullLocale,
  thumbnailRatio,
  columnLayout,
} from '../../utils/utils'
import Layout from '../../components/Layout/Layout'
import { getTranslations } from '../../i18n'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Button from '../../components/Button/Button'
import { connect } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SwiperOptions } from 'swiper/types/swiper-options'
import SwiperCore, { Pagination, Navigation, A11y } from 'swiper'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import Link from '../../components/Link/Link'
import NoMixedBasketModal from '../../components/Products/NoMixedBasketModal'
import CMSText, {
  isPrismicText,
  prismicText,
} from '../../components/Global/CMSText'
import { createClient, getSingleDocument } from '../../prismicio'
import { PrismicPage } from '../../interfaces/common/prismicPage'
import { RootState } from '../../redux/reducers/rootReducer'
import { InferGetStaticPropsType } from 'next/types'
import { PrismicGeneralConfig } from '../../interfaces/common/prismicGeneralConfig'
import FaqQuestion from '../../components/Faq/FaqQuestion'
import { PrismicRichText } from '@prismicio/react'
import htmlSerializer from '../../utils/htmlSerializer'

SwiperCore.use([Navigation, Pagination, A11y])

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      minHeight: '50vh',
    },
    section: {
      padding: theme.spacing(0, 0, 3),
    },
    sectionNoPadding: {
      padding: 0,
    },
    wysiwyg: {
      '&>*': {
        margin: theme.spacing(0, 0, 3),
      },
      '& img': {
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    hero: {
      minHeight: 300,
      position: 'relative',
      display: 'flex',
      overflow: 'hidden',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      [theme.breakpoints.up('lg')]: {
        minHeight: '80vh',
      },
    },
    heroContent: {
      zIndex: 2,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    heroImage: {
      zIndex: 1,
      position: 'absolute',
      display: 'block',
      top: 0,
      left: 0,
      right: 0,
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
    hero__ctas: {
      [theme.breakpoints.up('md')]: {},
      '&>a': {
        display: 'inline-block',
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
          margin: 0,
          '&+a': {
            margin: theme.spacing(0, 2),
          },
        },
      },
    },
    carouselTitle: {
      marginBottom: theme.spacing(3),
    },
    carouselCard: {},
    carouselLink: {},
    carouselArrows: {
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(0, 6),
      },
    },
    carouselNoArrows: {},
    carouselArrow: {
      zIndex: 2,
      padding: 0,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      minWidth: 32,
      width: 32,
      height: 32,
      background: theme.palette.common.white,
      '&[disabled]': {
        opacity: 0.3,
        cursor: 'default',
      },
    },
    carouselArrowPrev: {
      left: theme.spacing(1),
    },
    carouselArrowNext: {
      right: theme.spacing(1),
    },
    carouselSlideContent: {
      padding: theme.spacing(2, 0),
      color: theme.palette.common.black,
    },
    carouselSlideTitle: {},
    carouselSlideSubTitle: {},
    fullwidth: {
      position: 'relative',
      width: '100vw',
      transform: 'translateX(-50%) translateY(0)',
      left: '50%',
    },
    modal: {},
    modalContainer: {
      height: '100vh',
    },
    modalBox: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    close: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    popupImg: {
      maxWidth: '100%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      cursor: 'pointer',
    },
    modalContent: {
      background: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[1],
      '&>*:not([data-oembed-type=video])': {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
      },
    },
    faqTitle: {
      textAlign: 'center',
      color: theme.palette.common.black,
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
      },
    },
    faqContainer: {
      maxWidth: '700px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3),
    },
  })
)

type TPageProps = ReturnType<typeof mapStateToProps> &
  InferGetStaticPropsType<typeof getServerSideProps>

const mapStateToProps = (state: RootState) => ({
  cart: state.cart,
  parameters: state.parameters,
})

const Page = ({
  doc,
  main,
  inStoreText,
  popups,
  basket,
  accountRes,
  faqRes,
  banner,
}: TPageProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTitle = doc?.data.show_title
  const isCtaPrivacy = doc?.data.show_privacy_button
  const privacyBtnLabel = doc?.data?.privacy_button_label
    ? doc?.data?.privacy_button_label
    : t('texts:privacy:showCookie')
  const pageTitle = prismicText(doc?.data?.title, t('texts:page:title'))
  const pageBody = doc?.data?.body ? doc?.data?.body : []

  const aspectRatio = thumbnailRatio(main?.data?.thumbnail_ratio)

  const { trackPageView, trackEvent } = useMatomo()
  const [popupOpen, setPopupOpen] = useState({ id: null })
  const [expandedQuestion, setExpandedQuestion] = useState<number | null>(null)

  const handleExpandClick = useCallback(
    (index: number) => {
      if (expandedQuestion !== index) setExpandedQuestion(index)
      else setExpandedQuestion(null)
    },
    [expandedQuestion]
  )

  useEffect(() => {
    trackPageView({ documentTitle: `${pageTitle}` })
  }, [])

  const handlePopup = (id) => setPopupOpen({ id: id })
  const handlePopupClose = () => setPopupOpen({ id: null })

  const ModalPopup = (el) =>
    el.el?.id ? (
      <Modal
        open={popupOpen.id === el.el.id}
        onClose={handlePopupClose}
        className={clsx('modal', classes.modal)}
      >
        <Container className={clsx('modal__container', classes.modalContainer)}>
          <Fab
            aria-label="close"
            className={clsx('modal__close', classes.close)}
            onClick={() => setPopupOpen({ id: null })}
          >
            <CloseIcon />
          </Fab>
          <Box className={clsx('modal__box', classes.modalBox)}>
            <Fade in={popupOpen.id === el.el.id} timeout={300} mountOnEnter>
              <Box className={clsx('modal__content', classes.modalContent)}>
                <PrismicRichText
                  field={el.el?.data?.popup_content}
                  components={htmlSerializer}
                />
              </Box>
            </Fade>
          </Box>
        </Container>
      </Modal>
    ) : null

  return (
    <Layout
      mainLogo={main?.data?.logo}
      doc={main}
      t={t}
      inStoreText={inStoreText}
      metaTitle={prismicText(doc?.data?.meta_title_page, t('texts:page:title'))}
      metaDescription={doc?.data?.meta_description_page}
      metaKeywords={doc?.data?.meta_keywords_page}
      basket={basket}
      pageType={'cms'}
      account={accountRes}
      faq={faqRes}
      banner={banner}
      pageName={doc?.uid}
    >
      {isTitle && (
        <Container
          className={`page__container page__hero page__hero--${doc?.id}`}
        >
          <Box pt={4}>
            <Typography variant="h1" gutterBottom>
              {pageTitle}
            </Typography>
          </Box>
        </Container>
      )}
      {typeof window && isCtaPrivacy && typeof Cookiebot !== 'undefined' && (
        <Container className={`page__container page__container--${doc?.id}`}>
          <Box pb={4}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => Cookiebot.renew()}
            >
              {privacyBtnLabel}
            </Button>
          </Box>
        </Container>
      )}
      <div
        className={`page-content page__content--${doc?.id} ${classes.content}`}
      >
        {pageBody.length === 0 ? (
          <Container className={'page__container'}>
            {t('page:content')}
          </Container>
        ) : (
          // TODO: Move these sections to custom components by slice_type
          pageBody.map((item, index) => {
            if (item.slice_type === 'text') {
              const hasPopup = typeof item?.primary?.popup?.id !== 'undefined'
              const popup =
                hasPopup &&
                popups.results.filter(
                  (popup) => popup.id === item?.primary?.popup?.id
                )

              return (
                <section
                  key={`${item.slice_type}-${index}`}
                  id={`section-${item.slice_type}-${index}`}
                  className={`${classes.section} section-${item.slice_type}`}
                >
                  <Container
                    className={`page__container page__container--${item.slice_type}`}
                  >
                    <div
                      className={`page-wysiwyg ${classes.wysiwyg}`}
                      style={{ textAlign: item?.primary?.text_align }}
                    >
                      <CMSText data={item?.primary?.text} />

                      {item?.primary?.cta_link?.url && (
                        <Link href={item?.primary?.cta_link.url}>
                          <a>
                            <Button variant={'contained'} color={'primary'}>
                              {prismicText(item?.primary?.cta_text)}
                            </Button>
                          </a>
                        </Link>
                      )}
                      {hasPopup && (
                        <>
                          {isPrismicText(popup[0]?.data?.popup_cta) ? (
                            <Button
                              variant={'contained'}
                              color={'primary'}
                              onClick={() => handlePopup(popup[0]?.id)}
                            >
                              {prismicText(popup[0]?.data?.popup_cta)}
                            </Button>
                          ) : (
                            popup[0]?.data?.popup_image?.url && (
                              <img
                                className={classes.popupImg}
                                src={popup[0]?.data?.popup_image?.url}
                                onClick={() => handlePopup(popup[0]?.id)}
                              />
                            )
                          )}
                          <ModalPopup el={popup[0]} />
                        </>
                      )}
                    </div>
                  </Container>
                </section>
              )
            } else if (item.slice_type === 'columns') {
              const isFullScreen = item?.primary?.fullscreen
              const gridItemWidth = columnLayout(`${item.items.length}`)
              return (
                <section
                  key={`${item.slice_type}-${index}`}
                  id={`section-${item.slice_type}-${index}`}
                  className={clsx(
                    classes.section,
                    `section-${item.slice_type}`,
                    isFullScreen && !isMobile && classes.sectionNoPadding,
                    isFullScreen && `section-${item.slice_type}--fullscreen`
                  )}
                >
                  <Container
                    className={clsx(
                      'page__container',
                      `page__container--${item.slice_type}`,
                      isFullScreen &&
                        `page__container--${item.slice_type}--fullscreen`
                    )}
                    maxWidth={isFullScreen ? 'xl' : 'lg'}
                  >
                    <Grid
                      container
                      spacing={isFullScreen ? 0 : 3}
                      alignItems={isFullScreen ? 'center' : 'flex-start'}
                      justify="center"
                      wrap={isMobile ? 'wrap' : 'nowrap'}
                    >
                      {item.items?.map((el, index) => {
                        const hasPopup = typeof el?.popup?.id !== 'undefined'
                        const popup =
                          hasPopup &&
                          popups.results.filter(
                            (popup) => popup.id === el?.popup?.id
                          )

                        return (
                          <Grid
                            key={index}
                            id={`column-${index}`}
                            item
                            xs={12}
                            lg={gridItemWidth}
                          >
                            <div
                              className={`page-wysiwyg ${classes.wysiwyg}`}
                              style={{ textAlign: item?.primary?.text_align }}
                            >
                              {el?.column_content?.length === 1 &&
                              el.column_content[0]?.type === 'image' ? (
                                <img src={el.column_content[0].url} alt={''} />
                              ) : (
                                <CMSText data={el?.column_content} />
                              )}
                            </div>
                            {el?.cta_link?.url && (
                              <div
                                style={{ textAlign: item?.primary?.text_align }}
                              >
                                <Link href={el?.cta_link.url}>
                                  <a>
                                    <Button
                                      variant={'contained'}
                                      color={'primary'}
                                    >
                                      {prismicText(el?.cta_text)}
                                    </Button>
                                  </a>
                                </Link>
                              </div>
                            )}
                            {hasPopup && (
                              <div
                                style={{ textAlign: item?.primary?.text_align }}
                              >
                                <>
                                  {isPrismicText(popup[0]?.data?.popup_cta) ? (
                                    <Button
                                      variant={'contained'}
                                      color={'primary'}
                                      onClick={() => handlePopup(popup[0]?.id)}
                                    >
                                      {prismicText(popup[0]?.data?.popup_cta)}
                                    </Button>
                                  ) : (
                                    popup[0]?.data?.popup_image?.url && (
                                      <img
                                        className={classes.popupImg}
                                        src={popup[0]?.data?.popup_image?.url}
                                        onClick={() =>
                                          handlePopup(popup[0]?.id)
                                        }
                                      />
                                    )
                                  )}
                                  <ModalPopup el={popup[0]} />
                                </>
                              </div>
                            )}
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Container>
                </section>
              )
            } else if (item.slice_type === 'hero_section') {
              return (
                <section
                  key={`${item.slice_type}-${index}`}
                  id={`section-${item.slice_type}-${index}`}
                  className={`${classes.section} section-${item.slice_type}`}
                >
                  <div className={clsx(classes.hero, 'section_container')}>
                    <Container
                      className={clsx(classes.heroContent, 'hero_content')}
                    >
                      <Typography
                        variant="h1"
                        component={isTitle ? 'h2' : 'h1'}
                      >
                        {prismicText(item?.primary?.hero_title)}
                      </Typography>
                      {doc?.data?.body[0].items.length > 0 && (
                        <div className={clsx(classes.hero__ctas, 'ctas')}>
                          {doc?.data?.body[0].items.map((item, index) => (
                            <Link
                              key={index}
                              href={
                                item.cta_link?.url ? item.cta_link?.url : '/'
                              }
                            >
                              <a>
                                <Button
                                  variant={
                                    item.cta_style ? 'outlined' : 'contained'
                                  }
                                  color={
                                    item.cta_color ? 'secondary' : 'primary'
                                  }
                                  component="span"
                                  onClick={() =>
                                    trackEvent({
                                      category: `Vendre`,
                                      href: item?.cta_link?.url,
                                      action: `CTA n°${index} to page ${item?.cta_link?.url}`,
                                    })
                                  }
                                >
                                  {prismicText(item?.cta_label)}
                                </Button>
                              </a>
                            </Link>
                          ))}
                        </div>
                      )}
                    </Container>

                    {item.primary.background?.url !== undefined &&
                    isMobile &&
                    item.primary.background?.small?.url ? (
                      <img
                        className={clsx(classes.heroImage, 'hero_image')}
                        src={item.primary.background.small.url}
                        alt=""
                      />
                    ) : (
                      <img
                        className={clsx(classes.heroImage, 'hero_image')}
                        src={item.primary.background.url}
                        alt=""
                      />
                    )}
                  </div>
                </section>
              )
            } else if (item?.slice_type === 'widget') {
              return (
                <section
                  key={`${item?.slice_type}-${index}`}
                  id={`section-${item?.slice_type}-${index}`}
                  className={classes.section}
                >
                  <Container
                    className={`page__container page__container--${item?.slice_type}`}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: prismicText(item?.primary.code),
                      }}
                    />
                  </Container>
                </section>
              )
            } else if (item.slice_type === 'carousel') {
              const isArrows = item?.primary?.carousel_arrows
                ? item?.primary?.carousel_arrows
                : false
              const gridSpacing = columnSpacing(
                item?.primary?.carousel_card_spacing
              )
              const swiperParamsMobile: SwiperOptions = {
                centeredSlides: true,
                spaceBetween: 0,
                slidesPerView: 1.25,
                initialSlide: 0,
              }

              const swiperParamsDesktop: SwiperOptions = {
                navigation: {
                  nextEl: `#${item.slice_type}-${index}__next`,
                  prevEl: `#${item.slice_type}-${index}__prev`,
                },
                spaceBetween: theme.spacing(gridSpacing),
                centeredSlides: false,
                loop: false,
                breakpoints: {
                  1024: {
                    slidesPerView: item.primary?.slides_to_show
                      ? item.primary?.slides_to_show
                      : 3,
                  },
                },
              }

              const swiperParams = isMobile
                ? swiperParamsMobile
                : swiperParamsDesktop

              return (
                <section
                  key={`${item?.slice_type}-${index}`}
                  id={`section-${item?.slice_type}-${index}`}
                  className={`${classes.section} ${classes.fullwidth}`}
                >
                  <Container
                    className={`page__container page__container--${item?.slice_type}`}
                  >
                    {isPrismicText(item?.primary?.carousel_title) && (
                      <Typography
                        variant="h2"
                        className={`page-${item.slice_type}__title ${classes.carouselTitle}`}
                      >
                        {prismicText(item?.primary?.carousel_title)}
                      </Typography>
                    )}
                    <div
                      className={
                        isArrows
                          ? classes.carouselArrows
                          : classes.carouselNoArrows
                      }
                    >
                      {isArrows && (
                        <>
                          <Button
                            className={`${classes.carouselArrow} ${classes.carouselArrowPrev}`}
                            id={`${item.slice_type}-${index}__prev`}
                          >
                            <KeyboardArrowLeft />
                          </Button>
                          <Button
                            className={`${classes.carouselArrow} ${classes.carouselArrowNext}`}
                            id={`${item.slice_type}-${index}__next`}
                          >
                            <KeyboardArrowRight />
                          </Button>
                        </>
                      )}
                      <Swiper {...swiperParams}>
                        {item?.items?.map((slide, index) => (
                          <SwiperSlide key={index}>
                            <article className={classes.carouselCard}>
                              <Link
                                href={
                                  slide?.slide_link?.url
                                    ? slide?.slide_link?.url
                                    : ''
                                }
                              >
                                <a
                                  className={classes.carouselLink}
                                  target={
                                    slide?.slide_link?.target
                                      ? slide?.slide_link?.target
                                      : '_self'
                                  }
                                >
                                  {slide?.slide_image?.url && (
                                    <div
                                      className={`page-carousel-slide__media`}
                                      data-ratio={aspectRatio}
                                    >
                                      <img
                                        src={slide.slide_image.url}
                                        alt={''}
                                      />
                                    </div>
                                  )}
                                  <div
                                    className={`page-carousel-slide__content ${classes.carouselSlideContent}`}
                                  >
                                    {isPrismicText(slide?.slide_title) && (
                                      <Typography
                                        variant={'h5'}
                                        component={'h2'}
                                        color={'primary'}
                                        className={`page-carousel-slide__title ${classes.carouselSlideTitle}`}
                                      >
                                        {prismicText(slide?.slide_title)}
                                      </Typography>
                                    )}
                                    {isPrismicText(slide?.slide_subtitle) && (
                                      <Typography
                                        variant={'caption'}
                                        color={'primary'}
                                        className={`page-carousel-slide__subtitle ${classes.carouselSlideSubTitle}`}
                                      >
                                        {prismicText(slide?.slide_subtitle)}
                                      </Typography>
                                    )}
                                  </div>
                                </a>
                              </Link>
                            </article>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </Container>
                </section>
              )
            }
          })
        )}
        {!!doc?.data?.is_faq_active && (
          <section className="faq-wrap">
            <Typography
              variant="h2"
              component="h1"
              className={`faq-page__title ${classes.faqTitle}`}
            >
              <CMSText
                asText
                data={doc?.data?.faq_title}
                defaultText={t('texts:faq:title')}
              />
            </Typography>
            <Container className={`faq-content ${classes.faqContainer}`}>
              {doc?.data?.q_a?.map((item, index) => {
                return (
                  <div key={index}>
                    <FaqQuestion
                      handleExpandClick={handleExpandClick}
                      isExpanded={expandedQuestion === index}
                      questionId={index}
                      cat={0}
                      close_others={doc?.data?.close_others_on_click}
                      question={item.question}
                      answer={item.answer}
                    />
                  </div>
                )
              })}
            </Container>
          </section>
        )}
      </div>
      <NoMixedBasketModal basket={basket} />
    </Layout>
  )
}

export async function getServerSideProps({ params, locale, previewData }) {
  const client = createClient({ previewData })
  const lang = setFullLocale(locale)

  let doc: PrismicPage | null = null
  try {
    //When a page is not found, prismic throws an error
    doc = await client.getByUID('pages', params.uid, { lang })
    // eslint-disable-next-line no-empty
  } catch (e) {}

  if (!doc) {
    return {
      redirect: {
        destination: '/404',
        permanent: false,
      },
    }
  }

  const general: PrismicGeneralConfig | null = await getSingleDocument(
    client,
    lang,
    'general'
  )
  const basket = await getSingleDocument(client, lang, 'basket')
  const funnel = await getSingleDocument(client, lang, 'funnel')
  const inStoreText = await getSingleDocument(client, lang, 'instore')
  const banner = await getSingleDocument(client, lang, 'banner')
  const accountRes = await getSingleDocument(client, lang, 'account')
  const faqRes = await getSingleDocument(client, lang, 'faq')
  const popups = await client.get({
    predicates: prismic.predicate.at('document.type', 'popup'),
    lang,
  })

  return {
    props: {
      doc,
      basket,
      funnel,
      accountRes,
      faqRes,
      main: general,
      inStoreText,
      banner,
      popups,
      i18nResources: await getTranslations(locale, ['texts']),
    },
  }
}

export default connect(mapStateToProps)(Page)
