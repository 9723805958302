import React from 'react'
import { connect } from 'react-redux'
import GlobalModal from '../Global/GlobalModal'
import { Cart } from '../../typings/base'
import { ORDER_ITEM_TYPE } from '../../utils/constants'
import { setOpenNoMixedBasketModal } from '../../redux/actions/basket'
import CMSText from '../Global/CMSText'
import { useTranslation } from 'react-i18next'

type NoMixedBasketModalProps = {
  cart: Cart
  noMixedBasketModalIsOpen: boolean
  setOpenNoMixedBasketModal: (isOpen: boolean) => void
  basket: any
}

const NoMixedBasketModal: React.FC<NoMixedBasketModalProps> = ({
  cart,
  noMixedBasketModalIsOpen,
  setOpenNoMixedBasketModal,
  basket,
}) => {
  const { t } = useTranslation()

  const secondHandCart =
    cart?.item?.items &&
    cart?.item?.items.length &&
    cart?.item?.items[0]?.type === ORDER_ITEM_TYPE.SECOND_HAND

  const titleData = secondHandCart
    ? basket?.data?.second_hand_product_in_basket_title
    : basket?.data?.rental_product_in_basket_title

  const titleDefault = secondHandCart
    ? t('texts:general:no_mixed_basket:second_hand_product_in_basket_title')
    : t('texts:general:no_mixed_basket:rental_product_in_basket_title')

  const textData = secondHandCart
    ? basket?.data?.second_hand_product_in_basket_text
    : basket?.data?.rental_product_in_basket_text

  const textDefault = secondHandCart
    ? t('texts:general:no_mixed_basket:second_hand_product_in_basket_text')
    : t('texts:general:no_mixed_basket:rental_product_in_basket_text')

  return (
    <GlobalModal
      open={noMixedBasketModalIsOpen || false}
      onClose={() => setOpenNoMixedBasketModal(false)}
      modalTitle={{
        data: titleData,
        defaultText: titleDefault,
      }}
      customClass="size-modal"
    >
      <CMSText data={textData} defaultText={textDefault} />
    </GlobalModal>
  )
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  noMixedBasketModalIsOpen: state.basket.noMixedBasketModalIsOpen,
})

const mapDispatchToProps = {
  setOpenNoMixedBasketModal: setOpenNoMixedBasketModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(NoMixedBasketModal)
